/*------------------------------------------------------------------------------------
/ 1. Responsive For Extra large Device 2
--------------------------------------------------------------------------------------*/
@media (min-width: 1200px) and (max-width: 1600px){

}

/*------------------------------------------------------------------------------------
/ 2. Desktop Device
/--------------------------------------------------------------------------------------*/
@media (min-width: 992px) and (max-width: 1199px){

}

/*------------------------------------------------------------------------------------
/ 3. Responsive For Tab Device
/--------------------------------------------------------------------------------------*/
@media (min-width: 768px) and (max-width: 991px){

}

/*------------------------------------------------------------------------------------
/ 4. Responsive For Mobile & Tab
/--------------------------------------------------------------------------------------*/
@media (max-width: 991px){

}

@media (min-width: 768px) and (max-width: 991px){
    .header{padding:0rem;}

    .navbar {
        position: relative;
      }

      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 60vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
  
      .navbar-logo{
            height: 80px;
      }

      .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
      }
    
      .menu-icon i{
        color: var(--clr-gray);        
      }

      
      .menu-icon i:hover{
        color: var(--clr-graydark);        
      }

      .fa-times, .fa-bars {
        color: var(--clr-gray);
        font-size: 2rem;
      }
    
      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: var(--clr-accent);
        padding: 14px 20px;
        border: 1px solid var(--clr-accent);
        transition: all 0.3s ease-out;
      }
    
      .nav-links-mobile:hover {
        background: var(--clr-white);
        color: var(--clr-black);
        transition: 250ms;
      }

      
    .nav-menu.active {
        background: var(--clr-graylight) ;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
    
      .nav-link {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
        color: var(--clr-black) ;
      }
    
      .nav-links:hover {
        background-color: var(--clr-graylight);
        color: var(--clr-black) ;
        border-radius: 0;
      }

      .hero-home{
        border: 0px solid red;
        height: 650px;;
      }
    
      .hero-home > h1 {
        padding: 5rem 0 2rem 0;
        font-size: 20px;
        line-height: 50px;
      }
    
      .hero-home > p {
        padding: 0 20px;
      }
    
      .section{
        position: relative;
        padding: 5rem 0;
        min-height: 650px;
        width: 100%;
        border-bottom: 1px solid var(--clr-gray);
      }

      .container {
        padding: 0 2rem;
        width: 100%;
      }
    
      .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
      }
    
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: auto;
      }


    .item-img .button-container{
        margin-right: 85%;
    }

      
    .section-highlight-items{
        min-height: 750px;
    }

    .footer{
        padding: 0.5rem;
        padding-top: 5rem;
        height: auto;
    }
}
/*------------------------------------------------------------------------------------
/ 5. Responsive For Mobile Device
--------------------------------------------------------------------------------------*/
@media (min-width: 320px) and (max-width: 767px){
    .header{padding:0rem;}

    .hero-home{
        border: 0px solid red;
        height: 650px;;
      }
    
      .hero-home > h1 {
        padding: 5rem 0 2rem 0;
        font-size: 20px;
        line-height: 50px;
      }
    
      .hero-home > p {
        padding: 0 20px;
      }
    
      .section{
        position: relative;
        padding: 5rem 0;
        min-height: 650px;
        width: 100%;
        border-bottom: 1px solid var(--clr-gray);
      }

      .container {
        padding: 0 2rem;
        width: 100%;
      }
    
      .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
      }
    
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: auto;
      }

      .navbar {
        position: relative;
      }

      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 60vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
  
      .navbar-logo{
            height: 80px;
      }

      .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
      }
    
      .menu-icon i{
        color: var(--clr-gray);        
      }

      
      .menu-icon i:hover{
        color: var(--clr-graydark);        
      }

      .fa-times, .fa-bars {
        color: var(--clr-gray);
        font-size: 2rem;
      }
    
      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: var(--clr-accent);
        padding: 14px 20px;
        border: 1px solid var(--clr-accent);
        transition: all 0.3s ease-out;
      }
    
      .nav-links-mobile:hover {
        background: var(--clr-white);
        color: var(--clr-black);
        transition: 250ms;
      }

      
    .nav-menu.active {
        background: var(--clr-graylight) ;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
    
      .nav-link {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
        color: var(--clr-black) ;
      }
    
      .nav-links:hover {
        background-color: var(--clr-graylight);
        color: var(--clr-black) ;
        border-radius: 0;
      }

      
    .hero-text{
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }

    .hero-text h3{
        font-size: 2rem;
    }

    .section-products h3{
        font-size: 0.9rem;
    }

    .section-products h1{
        font-size: 2rem;  
        text-align: center; 
    }

    .item-img .button-container{
        margin-right: 182px;
    }

    .item-img .btn-small{
        margin-left: -10px;
    }

    .section-banner .section-img{
        margin: 0 auto;
    }

    .section-banner .button-container{
        width: 100%;
        margin: 0 auto;
    }

    .section-promo-items .section-img{
        border:0px solid red;
        text-align: center;
        margin: 0 auto;
    }

    .section-promo-items .button-container{
        margin: 0 auto;
        text-align: center;
    }

    .section-highlight-items .section-img{
        margin-left: -20px;
    }

    .section-highlight-items{
        height: 750px;
    }

    .footer{
        padding: 0.5rem;
        height: auto;
    }

    .footer-logo-container img{
        margin-left: -20px;
    }

    .footer-right h1{
        font-size: 40px;
    }

    .footer-form button{
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
