@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;900&display=swap');
/*==== BOOTSTRAP ====*/

:root {
     --txt-font-primary: 'Montserrat', sans-serif;
     --txt-font-secondary: 'Montserrat', sans-serif;
     --txt-font-accent: 'Montserrat', sans-serif;

     --clr-primary:#E95B66;
    --clr-secondary: #ce2e9f;
    --clr-tertiary: #7E2EB7;
    --clr-accent: #138bff;
    --clr-black: #212121;
    --clr-white: #FFFFFF;
    --clr-gray: #808080;
    --clr-graylight: #E6E7E6;
    --clr-graydark: #686868;

    --calc-inherit: calc(100% - 2px);

    --txt-size-h1: 2.5rem;
    --txt-size-h2: 2rem;
    --txt-size-h3: 1.8rem;
    --txt-size-h4: 1.5rem;
    --txt-size-h5: 1.2rem;
    --txt-size-normal: 1rem;
    --txt-size-small: 0.8rem;
    --txt-size-xsmall: 0.625rem;

    --fig-space-unit: 16px;
    --fig-size-maxwidth: 1200px;
    --fig-radius-round: 25%;
}


*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    border-width: 0;
    border-style: solid;
    margin:0;
    padding:0;    
}

html, body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;  
  -ms-overflow-style: scrollbar;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;   
  font-family: var(--txt-font-primary); 
  width: 100%;
}

@-ms-viewport {
width: device-width;
}

a,
a:active,
a:visited,
a:hover,
a:focus {
    text-decoration: none;
}

h1 {font-size: var(--txt-size-h1); font-weight: bold; letter-spacing: 1px; word-spacing: 1px; text-transform: uppercase;}
h2 {font-size: var(--txt-size-h3); font-weight: bold; letter-spacing: 1px; word-spacing: 1px; font-variant: small-caps;}
h3 {font-size: var(--txt-size-h3); font-weight: bold; letter-spacing: 1px; word-spacing: 1px;}
h4 {font-size: var(--txt-size-h4); font-weight: bold; letter-spacing: 1px; word-spacing: 1px;}
h5 {font-size: var(--txt-size-h5); font-weight: bold; letter-spacing: 1px; word-spacing: 1px;}

dl dt,
dl dd,
ul li,
ol li{
    margin: 0;
    padding: 0;
    list-style-type: none;
}


.container{
  width: var(--fig-size-maxwidth);
  padding: 0 2rem;
  margin: 0 auto;
}

.shadow{
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.05), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.12);
}

.visible {
visibility: visible !important;
}
.invisible {
visibility: hidden !important;
}


.border {
  border: 1px solid var(--clr-graydark);
}

.spacer{
  display: block;
  width: 100%;
  position: relative;
  padding: 40px 0px;
}

.bg-primary {
  background-color: var(--clr-primary) !important;
}

.bg-secondary {
background-color: var(--clr-secondary) !important;
}

.bg-accent {
background-color: var(--clr-accent) !important;
}

.bg-gray {
background-color: var(--clr-graylight) !important;
}

.bg-white {
background-color: var(--clr-white) !important;
}

.bg-white {
background-color: var(--clr-white) !important;
}

.bg-black {
background-color: var(--clr-black) !important;
}

.bg-transparent {
background-color: transparent !important;
}


/*---- Grid System ----*/

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.col-1 {
  flex-basis: calc(8.33% - 20px);
  margin: 10px;
}

.col-2 {
  flex-basis: calc(16.66% - 20px);
  margin: 10px;
}

.col-3 {
  flex-basis: calc(25% - 20px);
  margin: 10px;
}

.col-4 {
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
}

.col-5 {
  flex-basis: calc(41.66% - 20px);
  margin: 10px;
}

.col-6 {
  flex-basis: calc(50% - 20px);
  margin: 10px;
}

.col-7 {
  flex-basis: calc(58.33% - 20px);
  margin: 10px;
}

.col-8 {
  flex-basis: calc(66.66% - 20px);
  margin: 10px;
}

.col-9 {
  flex-basis: calc(75% - 20px);
  margin: 10px;
}

.col-10 {
  flex-basis: calc(83.33% - 20px);
  margin: 10px;
}

.col-11 {
  flex-basis: calc(91.66% - 20px);
  margin: 10px;
}

.col-12 {
  flex-basis: calc(100% - 20px);
  margin: 10px;
}

