
.header{
    width: 100%;
    height: 80px;
    background:transparent;
    position: absolute;
    top: 0;
    z-index: 999;
    color: var(--clr-white);
}

.hidden{
    display: none;
    visibility: hidden;
}

.fixed {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    color: var(--clr-gray);
    background: var(--clr-white);
    box-shadow: 0px -5px 5px 5px var(--clr-gray);    
}

.navbar {
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
  }

  .navbar-container {
    display: flex;
    justify-content:space-between;
    align-items: center;
    align-content: center;
    height: 80px;
    width: var(--fig-size-maxwidth);
    border: 0px solid red;
  }
  
  .navbar-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    height: 100px;
    padding: 1rem 0;
  }

  .nav-menu {
    display: flex;
    margin-right: 2rem;
  }
  
  .nav-item {
    box-sizing: border-box;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    color: var(--clr-gray);
    text-transform: uppercase;
    letter-spacing: 1px;
    word-spacing: 1px;
    font-size: 1rem;
    cursor: pointer;
    border-bottom: none !important;    
  }

  .fixed .nav-link {
    color: var(--clr-gray);
  }

  
  .nav-link:hover {
    border-bottom: 4px solid var(--clr-white) ;
    transition: all 0.2s ease-out;
    color: var(--clr-graydark);
  }

  
  nav-link:active,  
  nav-link:link {
    color: var(--clr-white);
  }

  
  .fa-bars {
    color: var(--clr-white) ;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  .menu-icon i{
    color: var(--clr-white) ;    
  }

  .hero-home{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-branding{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .hero-branding img {
    height: 80px;
    opacity: 0.2;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .section{
    position: relative;
    padding: 5rem 0;
    min-height: 705px;
    width: 100%;
  }

  .section-light{
    background-color: var(--clr-white);
  }

  .section-dark{
    background-color: var(--clr-black);
  }

  .section-main{
    background: url('/public/assets/img/faridah_cover.png') center/cover no-repeat;
  }

  .button-container{
    box-sizing: border-box;
    margin: 2rem 0;
  }

  .btn {
    padding: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    letter-spacing: 1px;
    word-spacing: 1px;
    font-family: var(--txt-font-primary);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    min-width: 160px;
    height: 45px;
    line-height: 22px;
    text-transform: uppercase; 
  }

  .btn:hover{
    transition: all 0.3s ease-out;
  }


  .btn-primary-light {
    background-color: var(--clr-white);
    color: var(--clr-black);
  }

  .btn-secondary-light {
    background-color: transparent;
    color: var(--clr-white);
    border: 2px solid var(--clr-white);
  }

  .btn-primary-dark {
    background-color: var(--clr-black);
    color: var(--clr-white);
  }

  .btn-secondary-dark {
    background-color: transparent;
    color: var(--clr-black);
    border: 2px solid var(--clr-black);
  }

  .btn-primary-light:hover {
    color: var(--clr-white);
    background-color: var(--clr-black);
  }
  
  .btn-secondary-light:hover {
    background-color: var(--clr-white);
    color: var(--clr-black);
    border: 1px solid var(--clr-black);
  }

  .btn-primary-dark:hover {
    color: var(--clr-black);
    background-color: var(--clr-white);
  }
  
  .btn-secondary-dark:hover {
    background-color: var(--clr-black);
    color: var(--clr-white);
    border: 1px solid var(--clr-white);
  }

  .btn-medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn-large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn-small {
    padding: 4px 10px;
    font-size: 14px;
    min-width:100px;
  }
  
  .btn-small:hover,
  .btn-large:hover,
  .btn-medium:hover {
    transition: all 0.3s ease-out;
    background: var(--clr-white);
    color: var(--clr-primary);
    transition: 250ms;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.05), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.12); 
  }


  .hero-text{
    width: 100px;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .hero-text h3{
    font-size: 4rem;
    color: var(--clr-white);
    letter-spacing: 0.5rem;
    word-spacing: 1rem;
    font-weight: 600;
  }

  .section-products{
    box-sizing: border-box;
  }

  .section-products h3{
    text-transform: capitalize;
    letter-spacing: 2px;
    word-spacing: 2px;
    color: var(--clr-gray);
    font-size: 20px;
    font-weight: 600;
  }

  .section-products p{
    text-transform: capitalize;
    letter-spacing: 1px;
    word-spacing: 1px;
    color: var(--clr-black);
    font-size: 14px;
    padding: 1rem 0;
    line-height: 20px;    
  }

    .section-products h1{
        letter-spacing: 4px;
        word-spacing: 2px;
        color: var(--clr-black);
        font-size: 60px;
        text-transform: none;
        text-align: right;
        font-weight: 700;   
    }

    .product-block{
        box-sizing: border-box;
        background-color: var(--clr-graylight);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }

    .item-box{
        display: flex;
        flex-direction: column;
    }

    .item-info {
        padding: 1rem;
        margin: 0;
    }

    .item-info label{
        display: block;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 2px;
        word-spacing: 2px;
    }

    .item-info span{
        display: block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        word-spacing: 2px;
    }

    .item-img {
        display: flex;
        flex-direction: column;
        justify-items: flex-end;
        align-items: flex-end;
        align-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        height: 500px;
    }

    .item-img .btn-small{
        width: 102px;
    }

    .item-img .button-container {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        margin-right: 160px;
        margin-top: 455px;
        margin-bottom: 0px;
        margin-left: 2px;
        align-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
    }

    #item-earth{
        background: url('/public/assets/img/faridah-earth.jpg') center/cover no-repeat;
    }

    #item-air{
        background: url('/public/assets/img/faridah-air.jpg') center/cover no-repeat;
    }

    #item-fire{
        background: url('/public/assets/img/faridah-fire.jpg') center/cover no-repeat;
    }

    #item-water{
        background: url('/public/assets/img/faridah-water.jpg') center/cover no-repeat;
    }


    .section-promo-items{
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        background-color: var(--clr-white); 
    }

    .section-img img{
        height: 100px;
    }

    .section-banner{
      background: url('/public/assets/img/marktub-banner.png') center/cover no-repeat;
    }

    .section-info{
      color: var(--clr-white);
      text-transform: capitalize;
      letter-spacing: 1px;
      word-spacing: 1px;
      font-size: 14px;
      padding: 1rem 0;
      line-height: 20px;
    }

    .btn-bottom{
      margin-top: 200px;
      display: flex;
      align-self: flex-end;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .product-item-img{
      background: url('/public/assets/img/maktub-products.png') center/cover no-repeat;
      height: 500px;
      width: 100%;
    }

    .product-item-btn{
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
    }

    .section-highlight-items{
      display: flex;
      height: 600px;
    }

    .section-highlight-items .section-img{
      height: 60px;
    }

    
    .special-product-item{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 500px;
      background: url('/public/assets/img/almas-products.png') center/cover no-repeat;
    }

    .special-product-item img{
      object-fit: cover;
      width: 100%;
    }

    .special-product-item .button-container{
      display: flex;
      align-self: flex-start;
      justify-content: flex-end;
      align-items: flex-end;
      align-content: flex-end;
      flex-wrap: wrap;
      width: 200px;
      margin-top: 455px;
      margin-left: -38px;

}  

    .footer {
      background-color: var(--clr-graydark);
      padding: 4rem 2.5rem 2.5rem 2.5rem;
      min-height: 640px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      object-fit: contain;
    }
    
  
    .footer-right{
      border:0px solid red;
      width: inherit;
    }
  
    .footer-right h1{
      flex-wrap: wrap;
      color: var(--clr-white);
      font-size: 50px;
    }
  
    .copyright{
      border-top: 1px solid var(--clr-black);
      color: var(--clr-gray);
      letter-spacing: 1px;
      word-spacing: 1px;
      font-size: 10px;
    }

    .footer-logo-container img{
      height: 60px;
    }
    

    .footer-form input, 
    .footer-form textarea {
      background: transparent;
      border-bottom: 1px dotted var(--clr-white);
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      width: 100%;
      padding: 2rem 0 1rem 0;
      color: var(--clr-white);
      letter-spacing: 2px;
      word-spacing: 2px;
    }

    .form-input::placeholder,
    .form-textarea::placeholder{
        color: var(--clr-gray);
        letter-spacing: 2px;
        word-spacing: 2px;  
        font-size: 12px;       
        padding-bottom: 1px;
        margin-bottom: 1px;       
    }

    .footer-right form button{
      background-color: #E95B66;
      font-size: 14px;
      font-weight: 500;
      color: var(--clr-white);
      text-align: start;
      padding: 10px;
    }

    .footer-right form button::after{
        content: ">";
        padding-left: 80px;
    }

    
  .footer-address-container{
    width: 100%;
    height: 300px;
    padding-top: 100px;    
  }  

  .footer-address-container h3,
  .footer-address-container li{
    color: var(--clr-graylight);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    word-spacing: 2px;
  }

  
  .footer-address-container h3{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .social-links-container{
    display: flex;
    justify-content: space-between;
  }

  .social-links {
    display: flex;
    width: 100%;
  }

  .social-links li{
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    font-size: 12px;
  }

  .social-links li a{
    color: var(--clr-graylight);
  }